// Variables

$spacer-lg: 3rem;
$spacer-sm: 1.5rem;

$layout-spacer-lg: 3rem;
$layout-spacer-x: 1.5rem;

$app-sidebar-width: 230px;
$app-sidebar-width-collapsed: 70px;

$logo-height: 150px;
$logo-width: 250px;

$app-header-height: 100px;
