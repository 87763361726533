// Theme White

// Variables

$app-container-bg: #808080;
$app-sidebar-bg: rgb(255, 255, 255);
$app-header-bg: #ffd900;
$app-header-logo-bg: transparent;

// Content

.app-theme-white {
  &.app-container {
    background: $app-container-bg;
    background-color: #80808000;
  }

  .app-sidebar-bg {
    height: 100%;
    padding: 0;
  }
  .app-sidebar {
    background: $app-sidebar-bg;
    opacity: 1;
  }
  .app-sidebar-bg {
    height: 100%;
    padding: 0;
  }
  .app-page-title {
    background: rgba(255, 255, 255, 1);
    height: 100px;
  }

  .app-footer .app-footer__inner,
  .app-header {
    background: linear-gradient(137deg, #e0a317, #ffd900, #e36421, #f2d789);
    background-size: 800% 800%;
  }

  &.fixed-header {
    .app-header__logo {
      background: transparent;
      opacity: 1;
    }
  }
}
